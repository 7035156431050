import React from "react"

const FullPageVideo = ({ videoData, posterData }) => {
  return (
    <div className="fullPageVideoContainer">
      <video
        autoPlay
        playsInline={true}
        muted={true}
        loop={true}
        poster={posterData}
      >
        <source src={videoData} type="video/mp4" />
        Your browser doesn't support HTML5 video tag.
      </video>
      <div className="fullPageVideoContainer__overlay"></div>
    </div>
  )
}

export default FullPageVideo
