import React, { useEffect, useState } from "react"
import Hero from "./Hero"
import Typed from "typed.js"
import Loading from "./Loading"

const MainArea = () => {
  const [isLoading, setIsLoading] = useState(true)
  const options = {
    strings: [
      `I'M <span class="fw-semi-bold">Aishwarya^300 Soni.</span>`,
      `I'M A <span class="fw-semi-bold">Creative Director.</span>`,
      `I'M A <span class="fw-semi-bold">Cinematographer.</span>`,
      `I'M A <span class="fw-semi-bold">Producer.</span>`,
      `I'M A <span class="fw-semi-bold">Marketer.</span>`,
    ],
    typeSpeed: 100,
    backSpeed: 80,
    backDelay: 1e3,
    loop: true,
    smartBackspace: true,
  }
  useEffect(() => {
    const typed = new Typed(document.getElementById("typedSpan"), options)
    if (isLoading) {
      typed.stop()
    } else {
      typed.start()
    }
    return () => {
      typed.destroy()
    }
  }, [options, isLoading])

  return (
    <>
      {isLoading && <Loading setIsLoading={setIsLoading} />}
      <Hero isLoading={isLoading} />
    </>
  )
}

export default MainArea
