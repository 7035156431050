import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FiInstagram } from "react-icons/fi"
import Img from "gatsby-image"
import AnimatedNumber from "react-animated-number"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const AboutMe = () => {
  const [counterOne, setCounterOne] = useState(0)
  const [counterTwo, setCounterTwo] = useState(0)
  const [counterThree, setCounterThree] = useState(0)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    let AboutTL = gsap
      .timeline({
        onComplete: function () {
          setCounterOne(150)
          setTimeout(() => {
            setCounterTwo(50)
            setTimeout(() => {
              setCounterThree(10)
            }, 500)
          }, 500)
        },
        scrollTrigger: {
          trigger: `.aboutme`,
          start: "top 40%",
        },
      })
      .from(`.from-right`, {
        opacity: 0,
        x: 20,
        ease: "bounce.out",
        duration: 0.65,
        stagger: 0.35,
      })
      .from(`.from-bottom`, {
        y: 20,
        opacity: 0,
        duration: 0.2,
        stagger: 0.15,
      })
      .from(`.from-left`, {
        x: -20,
        opacity: 0,
        duration: 0.5,
      })
    return () => {
      AboutTL.kill()
    }
  }, [])

  let data = useStaticQuery(
    graphql`
      query {
        skills: allFile(filter: { relativeDirectory: { eq: "skills" } }) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        profile: file(relativeDirectory: { eq: "about-me" }) {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  let profile = data.profile
  data = data.skills.edges
  return (
    <section className="aboutme" id="section-about-me">
      <div className="aboutme__content">
        <div className="aboutme__content__description">
          <h2>
            <span className="from-right"> Aishwarya Soni</span>
          </h2>
          <h1 className="from-right">I am a social first content creator.</h1>
          <p className="from-right">
            My philosophy of creating content is to integrate my experience in
            video production with that in marketing to create brands, tell
            stories, and connect people. Over the years, I have developed a
            knack for making videos and an eye for marketing. I have evolved
            from creating 3-hour long films to 30-min short films to 2-minute
            commercials to 10-second micro-content on Instagram.
          </p>
          <div className="counters from-right">
            <div className="counter">
              <AnimatedNumber
                value={counterOne}
                formatValue={n => n.toFixed(0)}
                duration={1000}
              >
                150+
              </AnimatedNumber>
              <span>+</span>
              <p>Videos Created</p>
            </div>

            <div className="counter">
              <AnimatedNumber
                value={counterTwo}
                formatValue={n => n.toFixed(0)}
                duration={1000}
              >
                50+
              </AnimatedNumber>
              <span>+</span>
              <p>Happy Clients</p>
            </div>
            <div className="counter hide-mobile">
              <AnimatedNumber
                value={counterThree}
                formatValue={n => n.toFixed(0)}
                duration={1000}
              >
                10+
              </AnimatedNumber>
              <span>+</span>
              <p>Marketing Campaigns</p>
            </div>
          </div>
          <div className="skills">
            <h3 className="from-right">Creative Skills</h3>
            <div className="skills__container">
              {data.map(skill => (
                <div className="skill from-bottom" key={skill.node.name}>
                  <img src={skill.node.publicURL} alt={skill.node.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="aboutme__content__image from-left">
          <Img fluid={profile.childImageSharp.fluid} />
          <div className="instagram">
            <a
              href="https://www.instagram.com/theaishwaryasoni/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @theaishwaryasoni
            </a>
            <FiInstagram strokeWidth="2.5" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutMe
