import React, { useEffect } from "react"
import gsap from "gsap"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Player } from "@lottiefiles/react-lottie-player"

const Loading = ({ setIsLoading }) => {
  useEffect(() => {
    gsap
      .timeline({
        onStart: function () {
          disableBodyScroll(document.querySelector(".loading"), {
            reserveScrollBarGap: true,
          })
        },
        onComplete: function () {
          enableBodyScroll(document.querySelector(".loading"))
          setIsLoading(false)
        },
      })
      .from(".loading__content--text", {
        opacity: 0,
        duration: 0.5,
      })
      .from(
        ".loading__content--animation",
        {
          opacity: 0,
          duration: 0.75,
          y: -30,
        },
        "<1"
      )
      .to(".loading", {
        x: "-100vw",
        opacity: 0,
        duration: 0.5,
        delay: 4,
      })
  }, [setIsLoading])
  return (
    <div className="loading">
      <div className="loading__content">
        <div className="loading__content--animation">
          <Player
            autoplay={true}
            controls={false}
            loop={true}
            src="https://assets1.lottiefiles.com/packages/lf20_ZNmWRs.json"
            style={{ width: "100%" }}
            background="transparent"
            speed="1"
          ></Player>
        </div>
        <div className="loading__content--text">Loading</div>
      </div>
    </div>
  )
}

export default Loading
