import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { A11y, Pagination, Navigation } from "swiper"
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr"
import "swiper/swiper.scss"

// import "swiper/components/navigation/navigation.scss"

SwiperCore.use([A11y, Pagination, Navigation])

const swiperConfig = {
  slidesPerView: 1,
  spaceBetween: 1,
  grabCursor: true,
  speed: 750,
  allowTouchMove: true,
  updateOnWindowResize: true,
  pagination: { clickable: true },
  navigation: {
    nextEl: ".testimonials .swiper-navArrows__next",
    prevEl: ".testimonials .swiper-navArrows__prev",
  },
  centeredSlides: true,
  roundLengths: true,
  loop: true,
  autoHeight: true,
  // breakpoints: {
  //   1366: {
  //     slidesPerView: 3,
  //   },
  // },
}

const testimonialsData = [
  {
    id: 1,
    body:
      "Aishwarya Soni was a great addition to our team. He is extremely organized and a quick learner. Being great at communication & building relationships, all campaigns were executed smoothly from his end. During his initial days itself, he could be trusted with the bigger campaigns. His skills in production further added to his competence while completing a task.",
    name: "Renita",
    designation: "Group Account Manager, NoFiltr Social",
  },
  {
    id: 2,
    body:
      "Aishwarya Soni is an impeccable young talent. Not only is he sincere with his work but is also extremely reliable and a people's person. He assisted me as a talent manager for Tillotama Shome for a Tata Salt ad campaign. He was hands-on, efficient in coordination with the production house and the talent on creatives, legal and finances. He ensured red flags were raised with me at the right time and he handled all stakeholders involved like a pro. He was to ensure a smooth shoot and he did his job really well.",
    name: "Manpreet Bacchhar",
    designation: `Group CEO Vishal Bhardwaj Films <br /> Former Manager of Irrfan Khan`,
  },
  {
    id: 3,
    body:
      "There are few people who know it all, few who know nothing, and then there's a third kind - who make their way up by learning it. I have always known Aishwarya as someone who wouldn't be hesitant to not know and learn if needed. And I loved working with such a young chap who always was a seeker and a believer. Aishwarya has been a very integral part of my production house, and has worked very diligently on all the projects.",
    name: "Jay Bodas",
    designation: "Managing Director, Dil Se Films",
  },
]

const Testimonials = () => {
  return (
    <div className="testimonials" id="section-testimonials">
      <div className="testimonials__intro">
        <h1>
          Happy <span>Clients</span>
        </h1>
      </div>
      <Swiper {...swiperConfig}>
        {testimonialsData.map(t => (
          <SwiperSlide key={t.id}>
            <div className="testimonials__item">
              <p className="testimonials__item--body">{t.body}</p>
              <div className="testimonials__item--meta">
                <h4>{t.name}</h4>
                <span
                  dangerouslySetInnerHTML={{ __html: t.designation }}
                ></span>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="swiper-navArrows">
          <div className="swiper-navArrows__prev noTap">
            <GrFormPreviousLink />
          </div>
          <div className="swiper-navArrows__next noTap">
            <GrFormNextLink />
          </div>
        </div>
      </Swiper>
    </div>
  )
}

export default Testimonials
