import React, { useEffect } from "react"
import FullPageVideo from "./FullPageVideo"
import gsap from "gsap"
import scrollTo from "gatsby-plugin-smoothscroll"
import HeroVideo from "../videos/Hero.mp4"
import HeroVideoPoster from "../images/video-posters/hero.jpg"

const Hero = () => {
  useEffect(() => {
    const scrollTL = gsap.timeline({ repeat: -1, repeatDelay: 1 })
    scrollTL
      .to(".hero__scroll span", {
        duration: 1.25,
        y: 20,
        ease: "bounce.out",
      })
      .to(".hero__scroll span", {
        duration: 0.25,
        y: 0,
      })
    return () => {
      scrollTL.kill()
    }
  }, [])

  return (
    <header className="hero">
      <div className="hero__content">
        <div className="hero__content-welcome">Welcome</div>

        <h1 className="hero__content-typed">
          <div style={{ display: "none" }}>
            <p>Aishwarya Soni - Cinematographer | Producer</p>
          </div>
          <span id="typedSpan"></span>
        </h1>

        <div className="hero__content-para">
          A starry-eyed millennial with a passion for visual content and
          bringing creative ideas to life.
        </div>
      </div>

      <div
        className="hero__scroll"
        onClick={() => scrollTo("#section-about-me")}
        aria-label="scroll to about me"
      >
        <span></span>
      </div>
      <FullPageVideo videoData={HeroVideo} posterData={HeroVideoPoster} />
    </header>
  )
}

export default Hero
