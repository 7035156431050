import React, { useEffect } from "react"
import BlogCard from "./BlogCard"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const BlogCards = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    let BlogCardTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: `.blog`,
          start: "top 52%",
        },
      })
      .from(`.card-appear`, {
        opacity: 0,
        duration: 0.75,
        stagger: 0.65,
      })
    return () => {
      BlogCardTL.kill()
    }
  }, [])

  let data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "blog" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  data = data.images.edges

  const blogData = [
    {
      id: 1,
      imageData: data[0].node.childImageSharp.fluid,
      title: "For all those out there, trying.",
      slug: "for-all-those-out-there-trying",
    },
    {
      id: 2,
      imageData: data[1].node.childImageSharp.fluid,
      title: "To New Beginnings…",
      slug: "to-new-beginnings",
    },
    {
      id: 3,
      imageData: data[2].node.childImageSharp.fluid,
      title: "The ‘Happy’ about Birthdays!",
      slug: "the-happy-about-birthdays",
    },
  ]

  return (
    <div className="blog" id="section-blog">
      <div className="blog__intro">
        <h1>
          Latest <span>Blog</span> Posts
        </h1>
      </div>
      <div className="blog__grid">
        {blogData.map(card => (
          <BlogCard {...card} key={card.id} />
        ))}
      </div>
    </div>
  )
}

export default BlogCards
