import React, { useEffect } from "react"
import { Link } from "gatsby"
import FullPageVideoWithMask from "./FullPageVideoWithMask"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { HiOutlineArrowNarrowDown } from "react-icons/hi"
import TiktokVideo from "../videos/TIKTOK.mp4"
import PodcastsVideo from "../videos/PODCASTS.mp4"
import InstagramVideo from "../videos/INSTAGRAM.mp4"
import YoutubeVideo from "../videos/YT.mp4"
import PodcastsVideoPoster from "../images/video-posters/_podcasts_.jpg"
import TiktokVideoPoster from "../images/video-posters/_tiktok_.jpg"
import InstagramVideoPoster from "../images/video-posters/_insta_.jpg"
import YoutubeVideoPoster from "../images/video-posters/yt.jpg"

const myWork = [
  {
    id: 1,
    name: "Street style interviews | Fun brand/product videos",
    description:
      "Conceptualized, shot, and edited videos for several brands and influencers",
    maskPosition: "63%  39.2331%",
    videoData: TiktokVideo,
    posterData: TiktokVideoPoster,
    ctaLink: "/portfolio/tiktok",
  },
  {
    id: 2,
    name: "Social Cuts | Product Videos | Campaigns",
    description:
      "Produced, directed, strategised, and edited videos for several brands and influencers",
    maskPosition: "51.889% 44.0982%",
    videoData: InstagramVideo,
    posterData: InstagramVideoPoster,
    // ctaLink: "/portfolio/instagram",
  },
  {
    id: 3,
    name: "Personal Branding | A day in the life",
    description:
      "Created shows and vlogs for personal brands, and produced & edited podcasts, and distributed clips on reels, tiktok, youtube shorts, and linkedin",
    maskPosition: "44.2695% 80.9275%",
    videoData: PodcastsVideo,
    posterData: PodcastsVideoPoster,
    ctaLink: "/portfolio/youtube",
  },
  {
    id: 4,
    name: "Vlogs | Music Videos | Event Aftermovies | Shows",
    description:
      " Conceptualised, shot, edited, and directed several Youtube videos for several artists, institutions, and brands",
    maskPosition: "51.5% 50%",
    videoData: YoutubeVideo,
    posterData: YoutubeVideoPoster,
    ctaLink: "/portfolio/youtube",
  },
]

const MyWork = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    let MyWorkTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: `.myWorkSection`,
          start: "top 65%",
        },
      })
      .from(`.appear`, {
        opacity: 0,
        duration: 1,
        stagger: 1,
      })

    myWork.forEach(work => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: `.myWorkContainer-${work.id}`,
            start: "top top",
            end: "+=1750",
            scrub: 1,
            pin: true,
          },
        })
        .to(
          `.mask-${work.id}`,
          {
            maskSize: "auto 800vh",
            maskPosition: `${work.maskPosition}`,
            ease: "power2.in",
            rotation: 0.01,
            force3D: true,
          },
          0
        )
        .from(`.myWorkOverlay-${work.id}`, {
          opacity: 0,
        })
        .from(`.myWorkContent-${work.id} h2`, {
          opacity: 0,
          y: 100,
        })
        .from(`.myWorkContent-${work.id} p`, {
          opacity: 0,
          y: 100,
        })
        .from(`.myWorkContent-${work.id} a`, {
          opacity: 0,
          y: 100,
        })
    })

    return () => {
      MyWorkTL.kill()
    }
  }, [])
  return (
    <div className="myWorkSection" id="section-my-work">
      <div className="myWorkSection__intro">
        <h1 className="appear">
          I am an ardent believer in:{" "}
          <span>
            {" "}
            Marketing connects to the mind, whereas films connect to the soul{" "}
          </span>{" "}
          - a perfect amalgamation in the media ecosystem.
        </h1>
        <h3 className="appear">
          My Work
          <span>
            <HiOutlineArrowNarrowDown strokeWidth="0.2" />
          </span>
        </h3>
      </div>
      {myWork.map(work => (
        <FullPageVideoWithMask key={work.id} {...work} />
      ))}

      <div className="myWorkSection__outro">
        <Link to="/portfolio/films">
          <span>All Projects</span>
        </Link>

        <Link to="/marketing-campaigns">
          <span>Marketing Campaigns</span>
        </Link>
      </div>
    </div>
  )
}

export default MyWork
