import React from "react"
import { Link } from "gatsby"

const FullPageVideoWithMask = ({
  id,
  name,
  description,
  videoData,
  posterData,
  ctaLink,
}) => {
  return (
    <div className={`fullPageVideoContainerWithMask myWorkContainer-${id}`}>
      <div className={`mask-effect mask-${id}`}>
        <video
          autoPlay
          playsInline={true}
          muted={true}
          loop={true}
          poster={posterData}
        >
          <source src={videoData} type="video/mp4" />
          Your browser doesn't support HTML5 video tag.
        </video>
        <div
          className={`fullPageVideoContainerWithMask__overlay myWorkOverlay-${id}`}
        ></div>
        <div
          className={`fullPageVideoContainerWithMask__content myWorkContent-${id}`}
        >
          <h2>{name}</h2>
          <p>{description}</p>
          {ctaLink ? <Link to={ctaLink}>View My Work</Link> : null}
        </div>
      </div>
    </div>
  )
}

export default FullPageVideoWithMask
