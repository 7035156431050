import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { A11y, Pagination } from "swiper"
import TimelineYear from "./TimelineYear"
import { graphql, useStaticQuery } from "gatsby"

// Import Swiper styles
import "swiper/swiper.scss"

SwiperCore.use([A11y, Pagination])

const swiperConfig = {
  slidesPerView: 1,
  spaceBetween: 0,
  grabCursor: true,
  speed: 750,
  allowTouchMove: true,
  updateOnWindowResize: true,
  pagination: {
    el: ".timeline .swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div class="' +
        className +
        '">' +
        `<span>` +
        (index + 2015) +
        `</span>` +
        `</div>`
      )
    },
  },
}

const Timeline = () => {
  let data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "timeline" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  data = data.images.edges

  const timelineData = [
    {
      id: 1,
      year: 2015,
      title: "From PPTs to Multimedia Presentations",
      imageData: data[0].node.childImageSharp.fluid,
      description:
        "Always found powerpoint presentations monotonous and less engaging. For the first time, I tried my hands on video editing while making a pres on The Swachh Bharat Abhiyan by our PM Narendra Modi.",
    },
    {
      id: 2,
      year: 2016,
      title: "Entry into Bollywood & Business School",
      imageData: data[1].node.childImageSharp.fluid,
      description:
        "Got enrolled into one of the best business schools of the country to learn marketing, and at the same time into the centre of the country’s film industry - Bollywood, Mumbai.",
    },
    {
      id: 3,
      year: 2017,
      title: "Shift from Films to Ads",
      imageData: data[2].node.childImageSharp.fluid,
      description:
        "As much as I enjoyed being on film sets, this one time I got recommended for a Netflix advertisement in the capacity of an Assistant Director. I instantly fell in love with the world of ads, and so I explored more. ",
    },
    {
      id: 4,
      year: 2018,
      title: "Barter with Ad-Agencies",
      imageData: data[3].node.childImageSharp.fluid,
      description:
        "Having worked on sets of ad-films, I was now curious to explore the other side of it, which is, marketing, strategy, client servicing. So that summer, I approached one of the best ad-agencies and made a barter: I make videos for their client, and in exchange, they teach me marketing.",
    },
    {
      id: 5,
      year: 2019,
      title: "Influencer Marketing Influx",
      imageData: data[4].node.childImageSharp.fluid,
      description:
        "Seeing influencers create numerous video content daily and generating traction for brands, especially on Instagram & Tiktok, I added two plus two and entered this world of influencer marketing.",
    },
    {
      id: 6,
      year: 2020,
      title: "Producer/creator of videos & brands",
      imageData: data[5].node.childImageSharp.fluid,
      description:
        "Today I do what I love. I manage brands on social media, strategise as well as create videos for them. Videos are the next big thing - is what I had heard 5 years ago. This still stays true, and will continue to be.",
    },
  ]

  return (
    <div className="timeline" id="section-my-journey">
      <div className="timeline__intro">
        <h1>
          My <span>Journey</span>
        </h1>
      </div>
      <Swiper {...swiperConfig}>
        {timelineData.map(data => (
          <SwiperSlide key={data.id}>
            {({ isActive }) => (
              <TimelineYear
                style={isActive ? { opacity: 1 } : { opacity: 0 }}
                {...data}
              />
            )}
          </SwiperSlide>
        ))}
        <div className="swiper-pagination"></div>
      </Swiper>
    </div>
  )
}

export default Timeline
