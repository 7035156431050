import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { A11y, Autoplay } from "swiper"
import "swiper/swiper.scss"
import { graphql, useStaticQuery } from "gatsby"

SwiperCore.use([A11y, Autoplay])

const swiperConfig = {
  grabCursor: false,
  slidesPerView: "auto",
  loop: true,
  spaceBetween: 25,
  shortSwipes: false,
  longSwipes: false,
  allowTouchMove: false,
  autoplay: {
    delay: 1,
  },
  freeMode: true,
  speed: 1000,
  breakpoints: {
    1024: {
      spaceBetween: 60,
    },
  },
}

const Brands = () => {
  let data = useStaticQuery(
    graphql`
      query {
        brandsOne: allFile(
          filter: { relativeDirectory: { eq: "brands/brandsOne" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        brandsTwo: allFile(
          filter: { relativeDirectory: { eq: "brands/brandsTwo" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `
  )

  let brandsOne = data.brandsOne.edges
  let brandsTwo = data.brandsTwo.edges

  return (
    <div className="brands">
      <div className="brands__intro">
        <h1>
          <span>Brands</span> I've worked with
        </h1>
      </div>
      <Swiper {...swiperConfig} style={{ marginTop: "3.5rem" }}>
        {brandsOne.map(brand => (
          <SwiperSlide key={brand.node.name}>
            <div className="brands__slide">
              <img src={brand.node.publicURL} alt={brand.node.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper {...swiperConfig} dir="rtl">
        {brandsTwo.map(brand => (
          <SwiperSlide key={brand.node.name}>
            <div className="brands__slide">
              <img src={brand.node.publicURL} alt={brand.node.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Brands
