import React from "react"
import Img from "gatsby-image"

const TimelineYear = ({ style, year, title, description, imageData }) => {
  return (
    <div className="timeline__year" style={style}>
      <div className="timeline__year__image">
        <Img fluid={imageData} alt={title} />
      </div>
      <div className="timeline__year__content">
        <h3>{year}</h3>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default TimelineYear
