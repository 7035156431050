import React from "react"
import { BsArrowRight } from "react-icons/bs"
import Img from "gatsby-image"

const BlogCard = ({ id, imageData, title, slug }) => {
  return (
    <div className="blog__card card-appear">
      <a href={`/blog/${slug}`} className="blog__card--image">
        <Img fluid={imageData} alt={title} />
      </a>
      <a href={`/blog/${slug}`} className="blog__card--title">
        <h2>{title}</h2>
      </a>
      <div className="blog__card--cta">
        <a href={`/blog/${slug}`}>
          Read more
          <BsArrowRight />
        </a>
      </div>
    </div>
  )
}

export default BlogCard
