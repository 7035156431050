import React from "react"
import Layout from "../components/Layout"
import AboutMe from "../components/AboutMe"
import Timeline from "../components/Timeline"
import MyWork from "../components/MyWork"
import BlogCards from "../components/BlogCards"
import Testimonials from "../components/Testimonials"
import Brands from "../components/Brands"
import MainArea from "../components/MainArea"
import Seo from "../components/Seo"

export default function Home() {
  return (
    <Layout>
      <Seo />
      <MainArea />
      <AboutMe />
      <MyWork />
      <Timeline />
      <Testimonials />
      <Brands />
      <BlogCards />
    </Layout>
  )
}
